/**
 * @author RamaTulasi <rama.tulasi@sollishealth.com>
 */
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useHistory } from 'react-router-dom';
import { Col, Container, Row, Table } from 'reactstrap';
import BreadCrumb from '../../components/BreadCrumb';
import FooterCallUs from '../../components/FooterCallUs';
import {
    GetLabReportPdf,
    GetLabTestResultDetails,
    getPatientDetails,
} from '../../services/RestApi';
import { useOnlineStatus } from '../../utilities/useOnlineStatus';
import Avatar from 'react-avatar';
import { hideLoader, showLoader } from '../../utilities/Loader';
import { SYSTEM_ERROR_PAGE } from '../../utilities/Constants';
import { checkVersionDiff } from '../../utilities/helper';
import { LabFilesList } from './LabFilesList';
import { NoteFromProvider } from './NoteFromProvider';
import { MessageProviderButton } from 'features/message-provider';

function LabDetails() {    
    const isOnline = useOnlineStatus(); //network connection status
    const [labDetails, setLabDetails] = useState({
        labTestResultDetails: [
            {
                labTestResultDetail: [],
            },
        ],
    });
    const [labFiles, setLabFiles] = useState([]);
    const [memberDetails, setMemberDetails] = useState({});
    const _memberDetails = useSelector((state) => state.memberDetails);
    const [isPDFPreview, setIsPDFPreview] = useState(false);
    const [currentDownloadFile, setCurrentDownloadFile] = useState({});
    const [loading, setLoading] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');
    let { patientDetailsMember } = getPatientDetails(_memberDetails);
    let { id } = useParams();
    const history = useHistory();
    const labResult = useSelector((state) => state.labResult.labResult.filter(l => l.labResultId == id)[0]);
    
    const dispatch = useDispatch();

    const hidePdfViewer = () => {
        setIsPDFPreview(false);
    };
    const toggle1 = () => setIsPDFPreview(!isPDFPreview);
    useEffect(() => {
        showLoader();
        let memberDetails = _memberDetails || {};
        const { memberIds } = getPatientDetails(_memberDetails);

        setMemberDetails(memberDetails);
        let postData = {
            labResultId: id
        };
        

        GetLabTestResultDetails(postData)
            .then((resp) => {
                checkVersionDiff(dispatch, resp);
                
                setLabDetails(resp.data.data);
                setLabFiles(resp.data.fileNames);
                // console.log('labtest', resp.data.data[0].labTestResult[0]);

                // setLabDetails(resp.data.data[0].labTestResult[0]);
            })
            .catch(err => history.push(SYSTEM_ERROR_PAGE))
            .finally(err => {
                hideLoader();
            })               
    }, [_memberDetails]);

    const labreportPdf = () => {
        setLoading(true);
        let payload = {
            labHeaderId: id,
            patientName:
                patientDetailsMember[labDetails.memberID]?.memberFullname,
            dob: patientDetailsMember[labDetails.memberID]?.memberDOB,
            address: patientDetailsMember[labDetails.memberID]?.memberaddress,
            phoneNo: patientDetailsMember[labDetails.memberID]?.mobileNumber,
        };
        GetLabReportPdf(payload)
            .then((r) => {
                checkVersionDiff(dispatch, r);
                //let report = new Blob([r.data])
                //let url = URL.createObjectURL(report);
                //setPdfUrl(url);
                //window.open(url,"_blank")

                const url = window.URL.createObjectURL(new Blob([r.data]));
                console.log(url);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `LabReport.pdf`);

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            })
            .catch(err => history.push(SYSTEM_ERROR_PAGE))
            .finally(() => {
                setLoading(false);
            });
    };

    const { memberIds } = getPatientDetails(_memberDetails);
    const formattedDate = moment(labResult?.labReportDate).format('MM/DD/YYYY');

    return (
        <>
            <BreadCrumb titles={['Test Results', labResult?.labType || 'Details']} paths={['/labresults']} />
            <Container className="lab-details margin-top-large padding-bottom">
                <Row className="section-header inner-header">
                    {' '}
                    {/*Section Heading*/}
                    <Col lg="12">
                        <div className="text-center">
                            <Link to="/labresults">
                                <i className="fa-light fa-chevron-left" />
                            </Link>
                            <span className="heading mx-auto">
                                Lab Result Details
                            </span>
                        </div>
                    </Col>
                </Row>
                <Row className="box-wrapper h-auto">
                    <Col>
                        <div className="box mt-4 no-border">
                            <div className='member-avatar'>
                                <div style={{ display: 'inline-block', margin: '0 5px' }}>

                                    {patientDetailsMember[labResult?.memberId]?.color ? (
                                        <Avatar
                                            color={patientDetailsMember[labResult?.memberId]?.color}
                                            size="30"
                                            name={
                                                patientDetailsMember[labResult?.memberId]?.memberFullname
                                            }
                                            round={true}
                                        />
                                    ) : (
                                        <Avatar
                                            className="custom-avatar"
                                            color={'#72A0A7'}
                                            size="30"
                                            name={
                                                patientDetailsMember[labResult?.memberId]?.memberFullname
                                            }
                                            round={true}
                                        />
                                    )}
                                </div>
                                <span style={{ fontSize: '20px', fontWeight: 'normal', color: '#000' }}>
                                    {patientDetailsMember[labResult?.memberId]?.memberFullname}
                                </span>
                            </div>

                            <Row className="lab-header no-border">
                                {/* <Col lg="3" xs="3" className="details-right">
                                    <Link to="#">
                                        <p>
                                            {' '}
                                            <i className="fa-light fa-print link" />
                                        </p>
                                    </Link>

                                    <p className="download">
                                        {' '}
                                        {!loading ? (
                                            <i
                                                className="fa-light fa-download link"
                                                onClick={() => labreportPdf()}
                                            />
                                        ) : (
                                            <i className="fa-light fa-download link" />
                                        )}
                                    </p>
                                </Col> */}
                                <Col lg="12" xs="12" className="details-left">
                                    {/* <p>
                                        <strong>Sample Date:</strong>{' '}
                                        <span>
                                            {moment(
                                                labDetails.labSampleDate
                                            ).format('MM-DD-YYYY')}
                                        </span>
                                    </p>
                                    <p>
                                        <strong>Results:</strong>{' '}
                                        <span>
                                            {labDetails.labTestResult}{' '}
                                            <i className="bi-alert" />
                                        </span>
                                    </p>
                                    <p>
                                        <strong>Physician:</strong>{' '}
                                        <span>{labDetails.physicianName}</span>
                                    </p> */}
                                    
                                    <h4>
                                        {
                                            patientDetailsMember[
                                                labDetails.memberID
                                            ]?.memberUsername
                                        }
                                    </h4>
                                    <Row>
                                        <Col lg="6" xs="6">
                                            <div>
                                                <strong className='lab-date-header'>DATE RECEIVED</strong>
                                                <div>
                                                    <span>
                                                        {formattedDate}
                                                    </span>
                                                    <span className='lab-date-data'>
                                                        {moment(labResult.labReportDate).format('h:mm a')}
                                                    </span>

                                                </div>
                                            </div>
                                            {/* <p>
                                                <strong style={{whiteSpace: 'nowrap', fontWeight: '600'}}>DATE OF COLLECTION</strong>
                                                <span style={{display: 'block'}}>
                                                    {moment(labResult.labReportDate).format('MM/DD/YYYY k:mm:ss')}
                                                </span>
                                            </p> */}
                                        </Col>
                                        {/* <Col lg="6" xs="6">
                                            <p>
                                                <strong style={{whiteSpace: 'nowrap', fontWeight: '600'}}>DATE OF REPORT</strong>
                                                <span style={{display: 'block'}}>
                                                    {formattedDate}
                                                </span>
                                            </p>
                                        </Col> */}
                                    </Row>
                                    {/* <p>
                                        <strong style={{whiteSpace: 'nowrap', fontWeight: '600'}}>DATE RECEIVED</strong>
                                        <span style={{display: 'block'}}>
                                            {formattedDate}    
                                        </span>
                                    </p> */}
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <Row className="box-wrapper mb-5 h-auto">
                    <Col>
                        <div className="box mt-4">
                            {/* Lab Details Table */}
                            {labResult?.labType && (<div className="header-text text-center mx-2">
                                {labResult?.labType}
                            </div>)}
                            <Row className='lab-details-full-data'>
                                {(!labResult?.labType || (Array.isArray(labDetails) && labDetails.length === 0 && Array.isArray(labFiles) && labFiles.length === 0)) && (
                                    <>
                                        <Row className={`${!labResult?.labType ? 'lab-details-no-data-title' : 'lab-details-no-data'} `}>
                                            <br></br>
                                            <p className='labType'>Digital Results Not Available</p>
                                        </Row>
                                    </>
                                )}
                                <NoteFromProvider value={labResult?.noteToPatient} />
                                <LabFilesList fileNames={labFiles} />
                                {Array.isArray(labDetails) && labDetails.length > 0 && labDetails.map(
                                    (item, iter) => {
                                        const [refVal1, refVal2] = item.referenceRange.split('-');
                                        let result = item?.abnormalFlag ? item?.abnormalFlag : ''
                                        if (item.value) {
                                            result = item?.abnormalFlag ? item.value + ' - ' + item?.abnormalFlag : item?.abnormalFlag
                                        } 
                                        let resultClass = 'text-end ';
                                        if (item.abnormalFlag !== 'NORMAL') resultClass += 'abnormal-value';
                                        return (
                                            <div key={iter}>
                                                
                                                <Row className='lab-details-row-data1'>
                                                    <br></br>
                                                <p className='labType'>{item.labType}</p>
                                                    <Col lg='6' xs='6'>
                                                        <p>Results:</p>
                                                    </Col>
                                                    <Col lg='6' xs='6'>
                                                        {Number.isInteger(item.value)
                                                            ? <p className={resultClass}>{item.value} {item.unit}</p>
                                                            : <p className={resultClass}>{result}</p>}
                                                    </Col>
                                                </Row>
                                                <Row className='lab-details-row-data2'>
                                                    <Col lg='6' xs='6'>
                                                        <p>Reference Range:</p>
                                                    </Col>
                                                    <Col lg='6' xs='6' className='pl-0'>
                                                        {(Number.isInteger(refVal1) && Number.isInteger(refVal1))
                                                            ? <p className='text-end text-nowrap'>{refVal1} {item.unit} - {refVal2} {item.unit}</p>
                                                            : <p className='text-end text-nowrap'>{item?.referenceRange}</p>}
                                                    </Col>
                                                </Row>
                                            </div>
                                        )
                                    })
                                }
                                {/* <Table hover className="lab-table">
                                    {console.log('labDetails: ', labDetails)}
                                    {Array.isArray(labDetails) && labDetails.length > 0 && labDetails.map(
                                        (ld) => (
                                            <tr>
                                                <td className="left">
                                                    <p>{ld.value}</p>
                                                    <span>
                                                        {ld.value}
                                                    </span>
                                                </td>
                                                <td className="right">
                                                    {ld.labresultFlag === 0 ? (
                                                        <>
                                                            <p
                                                                style={{
                                                                    color: 'red',
                                                                }}
                                                            >
                                                                {
                                                                    ld.labattributevalue
                                                                }
                                                            </p>
                                                            <span
                                                                style={{
                                                                    color: 'red',
                                                                }}
                                                            >
                                                                {ld.labresult}
                                                            </span>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <p>
                                                                {
                                                                    ld.labattributevalue
                                                                }
                                                            </p>
                                                            <span>
                                                                {ld.labresult}
                                                            </span>
                                                        </>
                                                    )}
                                                </td>
                                            </tr>
                                        )
                                    )}
                                </Table> */}
                            </Row>
                        </div>
                    </Col>
                </Row>

                <Row className="lab-btn fixed-btm">
                    <Col lg="12">
                        <MessageProviderButton
                            subject={`Test Results: ${labResult.labType} ${moment(labResult.labReportDate).format('MM-DD-YYYY')}`}
                            type='Lab'
                            externalId='Group3'
                        />
                    </Col>
                </Row>
                <FooterCallUs />
            </Container>
        </>
    );
}

export default LabDetails;
