/**
 * @author RamaTulasi <rama.tulasi@sollishealth.com>
 */
import React, { useState, useEffect } from 'react';
import {
    Container,
    Row,
    Col,
    Label,
    Form,
    FormGroup,
    Input,
    Breadcrumb,
} from 'reactstrap';
import {
    GetLabTestResultDetails,
    getPatientDetails,
} from '../../services/RestApi';

import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from 'react-avatar';
import moment from 'moment';
import BreadCrumb from '../../components/BreadCrumb';
import { useHistory } from 'react-router-dom';
import { messageRequest, saveMessageContentRequest } from '../../services/RestApi';
import FooterCallUs from '../../components/FooterCallUs';
import { LAB_MESSAGE_RING_GROUP_ID } from '../../utilities/Constants';
import SuccessModal from '../../components/SuccessModal';
import FailureModal from '../../components/FailureModal';
import notify from '../../services/NotifyService';
import { checkVersionDiff } from '../../utilities/helper';
import { RichTextEditor } from 'sollishealth.core.react';

function LabDetailsMessage() {
    const history = useHistory();
    const [msgStatus, setMsgStatus] = useState();
    const [memberDetails, setMemberDetails] = useState({});
    const [noDependents, setNoDependents] = useState(false);
    const _memberDetails = useSelector((state) => state.memberDetails);
    let { patientDetailsMember } = getPatientDetails(_memberDetails);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [labDetails, setLabDetails] = useState({});
    let { id } = useParams();
    const dispatch = useDispatch();
    const _labResult = useSelector((state) => state.labResult.labResult.filter(l => l.labResultId == id)[0]);
    useEffect(() => {
        setMsgStatus();
        let memberDetails = _memberDetails || {};
        setMemberDetails(memberDetails);
        if (_labResult) {
            setLabDetails(_labResult)
        } else {
            let postData = {
                labResultId: id
            };
            GetLabTestResultDetails(postData).then((resp) => {
                checkVersionDiff(dispatch, resp);
                setLabDetails(resp.data.data);
            });
        }
    }, [_memberDetails]);

    const sendMessage = (e) => {
        e.preventDefault();

        let payload = {
            externalId: 'Group3',
            header: `Test Results: ${labDetails.labType} - ${moment(labDetails.specimenCollectionDate).format('MM-DD-YYYY')}`,
            type: `Lab`
        };

        setLoading(true);
        messageRequest(payload)
            .then((res) => {
                checkVersionDiff(dispatch, res);
                const data = res.data;
                payload = {
                    conversationId: data.conversationId,
                    externalId: data.externalId,
                    content: message,
                    staffMemberSentBy:  _memberDetails?.loginMember[0]?.memberFullname
                }
                saveMessageContentRequest(payload).then((res) =>{
                    checkVersionDiff(dispatch, res);
                    history.push('/labresults');
                    notify('Message sent', 'success');
                })
                .catch((e) => {
                    //notify('Message not sent','error');
                    setMsgStatus('Failed');
                 })
                .finally(() => {
                    setLoading(false);
                });
            })
            .catch((e) => {
               //notify('Message not sent','error');
               setMsgStatus('Failed');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Container className="lab-details-message margin-top-large">
            {/*Section Heading*/}
            <BreadCrumb subTitle={'Lab Result'} path="/labresults" />
            <Row className="section-header inner-header">
                {' '}
                {/*Section Heading*/}
                <Col lg="12">
                    <div className="text-center">
                        <div onClick={history.goBack}>
                            <i className="fa-light fa-chevron-left" />
                        </div>
                        <span className="heading mx-auto">Compose Message</span>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col>
                    <div className="button-group btn-left">
                        <div
                            onClick={history.goBack}
                            className="button-action btn-border"
                        >
                            cancel
                        </div>
                        {loading ? <div className="working mt-3"></div> : ''}
                        {message && !loading ? (
                            <button
                                onClick={sendMessage}
                                className="button-action btn-light"
                            >
                                <i class="fa-light fa-paper-plane-top" /> &nbsp;
                                send
                            </button>
                        ) : (
                            <button className="button-action btn-disable">
                                <i class="fa-light fa-paper-plane-top" /> &nbsp;
                                send
                            </button>
                        )}
                    </div>
                </Col>
            </Row>

            {/* Lab Restult Message */}
            <Row className="lab-msg box-wrapper">
                <Col>
                    <div className='box'>
                        <Form>
                            <FormGroup>
                                <Label>
                                    <p>From: {
                                        patientDetailsMember[
                                            labDetails.memberId
                                        ]?.memberFullname
                                    }</p>
                                    <p></p>
                                </Label>
                            </FormGroup>
                            <FormGroup>
                                <Label>
                                    <p>Subject:</p>
                                    <p>
                                        Test Results: {labDetails.labType} <br />
                                        {moment(labDetails.specimenCollectionDate).format(
                                            'MM-DD-YYYY'
                                        )}
                                    </p>
                                </Label>
                            </FormGroup>
                            <FormGroup>
                                <Label for="message" className="msg">
                                    To: Provider Triage
                                </Label>
                                <RichTextEditor
                                    editorValue={message}
                                    handleEditorChanged={setMessage}
                                />
                            </FormGroup>
                        </Form>
                    </div>

                </Col>
            </Row>
            {msgStatus === 'Success' ? (
                <SuccessModal path={'/labresults'} />
            ) : msgStatus === 'Failed' ? (
                <FailureModal />
            ) : (
                ''
            )}
            <FooterCallUs />
        </Container>
    );
}

export default LabDetailsMessage;
